@import url("https://fonts.googleapis.com/css2?family=Nunito&family=Roboto:wght@100;300;400&display=swap");

@font-face {
  font-family: gilRoy;
  src: url(Gilroy-Medium.ttf);
}

@font-face {
  font-family: gilRoy-semibold;
  src: url(Gilroy-SemiBold.ttf);
}

iframe {
  display: none

}
.iframetute{
  display: block;
}
.combinediframe{
  display: block;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* -webkit-text-fill-color: black; */
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

.nav {
  position: relative;
  top: 0;
  left: -100%;
  height: 100vh;
  /* padding: 1rem 1rem 0; */
  background-color: #fff;
  box-shadow: 1px 0 0 rgba(22, 8, 43, 0.1);
  z-index: var(--z-fixed);
  border-right: 1px solid #e9e9e9;
  transition: 0.9s;
  transition: all 0.2s ease-in-out;
  transition: transform 250ms;
  transition: margin-left 0.5s;

  transition: 0.3s;
  padding: 6px;
}

.nav__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 3rem;
  overflow: auto;
  scrollbar-width: none;
  /* For mozilla */
}

/* For Google Chrome and others */
.nav__container::-webkit-scrollbar {
  display: none;
}

.nav .img-top {
  display: none;
}

.nav:hover .img-top {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: #fff;
}

.nav .img-top-second {
  display: flex;
  position: absolute;
  bottom: 15px;
  width: 100%;
  left: 0;
}

.nav:hover .img-top-second {
  display: none;
}

.open-img-sidebar {
  width: 42%;
  aspect-ratio: 3/2;
  object-fit: contain;
  margin: auto;
}

.close-img-sidebar {
  width: 45%;
  aspect-ratio: 3/2;
  object-fit: contain;
  margin: auto;
}

/* ========== MEDIA QUERIES ==========*/
/* For small devices reduce search*/

@media screen and (min-width: 768px) {
  .nav {
    left: 0;

    /* padding: 1.2rem 1.5rem 0; */
    width: 67px;
    /* Reduced navbar */
  }

  /* Navbar expanded */
  .nav:hover {
    /* width: var(--nav-width); */
    min-width: 230px;
    /* transform: translateX(5px); */
  }
}

img-top .muidatagrid-virtualscroller {
  overflow: hidden !important;
}

.tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.tag::before {
  background: #fff;
  /*   border-radius: 10px; */
  border-radius: 8px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: "";
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  background: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.ql-container.ql-snow {
  height: 170px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #b0b6ba;
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  background: #ffff;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.Slxdj {
  background-color: #fff !important;
  color: #000 !important;
}

.kXteup {
  background-color: #fff !important;
  color: #000 !important;
}

.MuiDataGrid-menuIcon,
.MuiDataGrid-menuOpen {
  display: none !important;
}

.MuiDialog-paper::-webkit-scrollbar {
  display: none !important;
}

::-webkit-scrollbar {
  display: none;
}

.buildbox {
  height: 48px;
  width: 200px;
  display: flex;
  align-items: center;
  /* border-radius: 30px; */
  border-radius: 8px !important;
  position: relative;
  justify-content: center;
  cursor: pointer;
}

.MuiDataGrid-root,
.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within,
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiChip-root {
  border-radius: 6px !important;
}

.allColumnLeft {
  text-align: left;
  display: flex;
  margin-left: 15px;
  font-size: 14px;
}

.allColumnTextCenter {
  text-align: center;
  display: flex;
  margin: auto;
  font-size: 14px;
}

.allHeaderCommonColor {
  color: #5a6268;
}

.SomeColumnLeft {
  text-align: left;
  display: flex;
  font-size: 14px;
}

.oulined-btn {
  /* //border-radius: 30px !important; */
  border-radius: 8px !important;
  font-size: 15px !important;
  height: 40px !important;
  border: 2px solid !important;
}

.oulined-btn:hover {
  border: 2px solid !important;
}

.MuiPickersDay-root {
  font-weight: 600 !important;
}

.collegeheading {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.singlecollegeheading {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 800px) {
  .collegeheading {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.singlecollegeheading {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.leftlivejobcardscroll::-webkit-scrollbar {
  display: block;
  width: 10px;
  /* border: 5px solid white; */
}

.leftlivejobcardscroll::-webkit-scrollbar-thumb {
  background-color: #c0c2c3;
  /* background-clip: padding-box;
  border: 0.05em solid #eeeeee; */
}

.leftlivejobcardscroll::-webkit-scrollbar-track {
  background-color: #e0e2e3;
  border-radius: 2px;
}

.leftlivejobcardscroll::-webkit-scrollbar-button:single-button {
  background-color: #e0e2e3;
  display: block;
  border-style: solid;
  height: 10px;
  width: 10px;
  border-radius: 2px;
}

.leftlivejobcardscroll::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #555555 transparent;
}

.leftlivejobcardscroll::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}

.leftlivejobcardscroll::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 5px 5px 0 5px;
  border-color: #555555 transparent transparent transparent;
}

.leftlivejobcardscroll::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.livejobcardscroll::-webkit-scrollbar {
  display: block;
  width: 6px;
  height: 10px;
}

.livejobcardscroll::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 50px;
}

.livejobcardscroll::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}