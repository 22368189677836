.ql-toolbar.ql-snow {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.ql-container.ql-snow {
  height: 170px !important;
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .ql-container.ql-snow {
    height: auto !important;
    border: none !important;
    border-radius: 0px !important;
    padding-bottom: 60px !important;
  }
  .ql-toolbar.ql-snow {
    position: sticky !important;
    top: 0 !important;
    border-bottom: 1px solid #b3b3b3 !important;
    border-radius: 0px !important;
    z-index: 1000 !important;
    background-color: #fff !important;
  }
}
